<template>
  <v-app>
    <notifications position="top center" width="400px" classes="prd-notification" />
    <router-view></router-view>
  </v-app>
</template>

<script>
import ServiceFactory from "@/services/ServiceFactory";

export default {
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // UPDATE title
        let title = "";
        const paramId = this.$route.params.id;
        if (paramId) title += `#${paramId} | `;
        title += to.meta?.title;
        if (title) {
          document.title = to.meta.title;
          this.$store.dispatch("updateTitle", title);
        }

        // UPDATE Store currentService
        const currentServiceName = this.$store.state.currentService?.name;
        const serviceName = to.meta?.serviceName;
        const newService = serviceName ? ServiceFactory.get(to.meta.serviceName) : {};

        if (!from || currentServiceName !== newService.name) {
          this.$store.dispatch("updateCurrentService", newService);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.prd-notification {
  padding: 10px;
  margin: 10px;
  font-size: 0.9em;

  color: white;
  background: #195ae0 !important;
  border-left: 5px solid #1343a7 !important;

  &.warn {
    background: #ffbf3f !important;
    border-left-color: #e39e37 !important;
  }

  &.error {
    background: #ff4344 !important;
    border-left-color: #bb2e31 !important;
  }

  &.success {
    background: #00bba7 !important;
    border-left-color: #018d7e !important;
  }
}
</style>
